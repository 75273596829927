import * as React from "react";

import Layout from "../components/Base/Layout";
import { SvgBackground } from "../components/Base/SvgBackground";
import Ty from "../components/Registration/TyForRegistration";

export default function TyForRegistration() {
  return (
    <Layout>
      <SvgBackground position="absolute" top="0" right="0"></SvgBackground>
      <Ty></Ty>
    </Layout>
  );
}
